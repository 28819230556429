@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 20px;

	.slide {
		width: 152px;
		height: 165px;

		@include tablet {
			width: 160px;
		}
	}
}
