@import "~/styles/mixins.scss";
.wrapper {
	padding: 20px;
	display: flex;
	width: 152px;
	height: 165px;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	margin: 0 20px 20px 0;

	@include tablet {
		width: 160px;
	}

	.imageWrapper {
		display: flex;
		flex-shrink: 0;
	}

	.name {
		font-size: 12px;
		line-height: 14px;
		color: $navy;
		margin-top: 10px;
		white-space: pre-line;
	}

	.count {
		margin-left: 5px;
		font-size: 12px;
		line-height: 14px;
		color: $green;
	}
}
