@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	overflow: hidden;
	margin-top: 16px;
	margin-right: -16px;

	@include tablet {
		margin-top: 0;
		margin-right: 0;
	}

	.category {
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		min-width: 96px;
		height: 32px;
		font-size: 14px;
		color: $green;
		border: 1px solid $green;
		padding: 8px;
		transition: all .3s ease;

		@include tablet {
			min-width: 144px;
			height: 40px;
		}

		&:hover {
			color: $white;
			background-color: $green;
		}
	}

	.slide {
		width: fit-content;

		&:hover {
			cursor: pointer;
		}
	}
}
