@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	.list {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.item {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 4px 0;
			font-size: 16px;
			text-align: left;

			.reference {
				width: 100px;
				margin-right: 8px;
			}
		}
	}

	.button {
		margin-top: 24px;
	}
}

.modal {
	max-width: 850px;
}
