@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	.description {
		display: flex;
		margin-top: 12px;
		align-items: flex-start;

		.logotype {
			margin-right: 16px;
			flex-shrink: 0;
		}

		.text {
			font-size: 14px;
			line-height: 20px;
			color: $black55;
			max-width: 630px;
		}
	}
}
