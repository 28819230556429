@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-top: 64px;

	@include tablet {
		margin: 0;
	}

	.title {
		font-size: 16px;
		color: $black65;
		line-height: 19px;
		font-weight: 700;
		margin-bottom: 12px;
	}

	.button {
		margin: 32px 0 16px;

		@include tablet {
			max-width: 291px;
		}

		@include small-desktop {
			margin-bottom: 0;
		}
	}

	.information {
		font-size: 12px;
		line-height: 17px;
		color: $black50;

		@include small-desktop {
			display: none;
		}
	}
}
