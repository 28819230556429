@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	line-height: 17px;

	@include tablet {
		margin-top: 60px;
	}

	.content {
		max-width: 900px;
		max-height: 118px;
		overflow: hidden;
		transition: .5s ease max-height;
		will-change: max-height;
		font-size: 14px;
		line-height: 18px;

		@include tablet {
			max-width: 80%;
		}

		&:before {
			content: "";
			width: 100%;
			height: 130px;
			position: absolute;
			left: 0;
			background: linear-gradient(transparent, $white);
		}

		&.fullText {
			max-height: max-content;

			&:before {
				content: none;
			}
		}

		h1,
		h2,
		h3,
		h4 {
			font-weight: 600;
		}

		h1 {
			font-size: 24px;
		}

		h2 {
			font-size: 18px;
			margin: 20px 0 10px;
		}

		h3 {
			font-size: 16px;
		}

		h4,
		h5 {
			font-size: 14px;
		}

		h6 {
			font-size: 12px;
		}

		a {
			color: $green;
			text-decoration: underline;

			&:hover {
				color: $green-dark;
			}
		}

		p {
			margin-top: 16px;
		}

		ul {
			padding: 16px 0;

			li {
				position: relative;
				padding-left: 15px;
				margin-bottom: 3px;
				line-height: 23px;

				&:before {
					position: absolute;
					content: "";
					border-color: transparent $green;
					border-style: solid;
					border-width: 7px 0 7px 7px;
					display: block;
					height: 0;
					width: 0;
					left: 0;
					top: 6px;
				}
			}
		}
	}

	.toggleButton {
		display: flex;
		margin: 16px 0 0;
		position: relative;

		&.fullText {
			&:before {
				content: none;
			}
		}

		&:before {
			content: "";
			width: 100%;
			height: 80px;
			position: absolute;
			top: -80px;
			left: 0;
			background: linear-gradient(transparent, $white);
		}
	}

	.headerTitle {
		margin-bottom: unset;
	}
}
