@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	margin-top: 16px;

	@include small-desktop {
		display: none;
	}

	.caption {
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
		color: $black65;
	}

	.info {
		margin-top: 16px;
		font-size: 14px;
		line-height: 18px;
		color: $black65;
	}

	.image {
		margin: 16px auto 0;
		display: block;
	}
}
