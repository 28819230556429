@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 16px;
	line-height: 20px;

	@include desktop {
		margin-top: 32px;
	}

	.title {
		color: $black65;
		margin: 7px 0 16px;
		font-size: 26px;
	}

	.description {
		font-size: 14px;
		line-height: 20px;
	}

	.button {
		display: inline-block;
	}
}
