@import "~/styles/mixins.scss";
.wrapper {
	display: inline-block !important;
	padding: 4px;
	margin: 0 12px 0 0;
	cursor: pointer;
	color: $navy;

	&.active {
		font-weight: 700;
	}

	&.isMozaic {
		font-size: 14px;
		color: $color-grey-900;
	}
}
