@import "~/styles/mixins.scss";
.wrapper {
	border-top: 1px solid $black10;

	@include desktop {
		border-top: none;
		height: 42px;
		background: $black5;
		padding-left: 16px;
		display: flex;
		align-items: center;
		font-weight: 700;
		font-size: 16px;
		color: $black65;
	}

	&.open {
		.headerWrapper {
			.icon {
				transform: rotate(270deg);
			}
		}
	}

	.headerWrapper {
		height: 42px;
		background: $black5;
		padding: 0 25px 0 16px;
		display: flex;
		font-weight: 700;
		font-size: 16px;
		color: $navy;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		cursor: pointer;

		.header {
			width: 155px;
		}

		.icon {
			transition: transform .2s ease;
			width: 15px;
			transform: rotate(90deg);
			will-change: transform;
		}
	}
}
