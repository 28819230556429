/* stylelint-disable color-no-hex */
$color-grey-100: #e6e6e6;
$color-grey-200: #ccc;
$color-grey-300: #b3b3b3;
$color-grey-400: #999;
$color-grey-500: #808080;
$color-grey-600: #666;
$color-grey-700: #4d4d4d;
$color-grey-800: #333;
$color-grey-900: #191919;
$color-grey-999: #000;
$color-grey-000: #fff;

$color-secondary-green-500: #46a610;
$color-primary-01-600: #188803;
$color-danger-600: #c61112;

$color-orange-100: #fdf1e8;
$color-orange-500: #ea7315;
$color-orange-600: #c65200;

$color-info-500: #0b96cc;
/* stylelint-enable color-no-hex */
