@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-wrap: wrap;
	margin: 41px -16px 70px 0;

	@include tablet {
		margin: 41px 0 70px;
	}
}
