@import "~/styles/mixins.scss";
.wrapper {
	.slider {
		:global {
			.rc-slider-track {
				background: $navy;
				border-radius: 3px;
				height: 5px;
			}

			.rc-slider-rail {
				background: $black10;
				height: 5px;
			}

			.rc-slider-handle {
				border: 2px solid $navy;
				width: 18px;
				height: 18px;
				background: $white;
				opacity: 1;
			}

			.rc-slider-handle-dragging {
				box-shadow: none;
			}
		}
	}
}
