@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex: 1;
	flex-direction: column;

	@include tablet {
		margin-right: 25px;
	}

	.button {
		margin: 32px 0 16px;

		@include tablet {
			max-width: 291px;
		}
	}

	.title {
		font-size: 16px;
		color: $black65;
		line-height: 19px;
		font-weight: 700;
		margin-bottom: 12px;

		&.isMozaic {
			color: $black;
		}
	}

	.input {
		@include tablet {
			max-width: 365px;
		}
	}

	.information {
		font-size: 12px;
		line-height: 17px;
		color: $black50;

		@include tablet {
			max-width: 365px;
		}
	}
}
