// COLORS - the same in ~/statics/variables -> vars.colors
@import "./mozaicColors";
/* stylelint-disable color-no-hex */
$green-light: #ddedc8;
$green: #78be20;
$green-dark: #69a61c;
$green-dark-badge: #005f61;
$green-light-bow-adornment: #54b331;

$green-kobi: #188803;
$green-kobi-primary: #46a610;
$green-kobi-success: #ebf5de;

$navy: #00263a;
$navy-dark: #001621;
$navy-light: rgba(27, 95, 129, 10%);

$teal-light: #3bb391;
$teal-light15: rgba(59, 179, 145, 15%);
$teal: #32997c;
$teal-dark: #005f61;

$red: #da291c;
$red-dark: #da281c;

$gray: #595959;
$gray-medium: #a9a9a9;
$gray-light-kobi: #e6e6e6;
$gray90: #e5e5e5;
$gray95: #f2f2f2;
$gray15: rgba(126, 126, 126, 15%);
$gray55: rgba(126, 126, 126, 55%);

$gray-kobi-900: #191919;
$gray-kobi-800: #333;
$gray-kobi-600: #666;

$inpost-orange: #ffcd00;

$white: #fff;
$white0: rgba(255, 255, 255, 0%);
$white05: rgba(255, 255, 255, 0.05%);
$white10: rgba(255, 255, 255, 10%);
$white20: rgba(255, 255, 255, 20%);
$white30: rgba(255, 255, 255, 30%);
$white50: rgba(255, 255, 255, 50%);
$white75: rgba(255, 255, 255, 75%);

$black: #000;
$black3: rgba(0, 0, 0, 3%);
$black5: rgba(0, 0, 0, 5%);
$black10: rgba(0, 0, 0, 10%);
$black15: rgba(0, 0, 0, 15%);
$black20: rgba(0, 0, 0, 20%);
$black30: rgba(0, 0, 0, 30%);
$black40: rgba(0, 0, 0, 40%);
$black50: rgba(0, 0, 0, 50%);
$black55: rgba(0, 0, 0, 55%);
$black65: rgba(0, 0, 0, 65%);
$black70: rgba(0, 0, 0, 70%);

$blue: #0092bc;
$orange: #ffa300;
$creamy: #f8f8f8;

$alert-success: #e0f6e0;
$alert-success-text: #6f926f;
$alert-danger: #fbeaea;
$alert-danger-text: #9e5656;
$alert-warning: #ffeed0;
$alert-warning-text: #806029;
/* stylelint-enable color-no-hex */

// GRID (the same must be in useMediaQuery)
$view-size-small-mobile: 360px;
$view-size-medium-mobile: 380px;
$view-size-mobile: 480px;
$view-size-tablet: 768px;
$view-size-small-desktop: 1024px;
$view-size-desktop: 1200px;
$view-size-large-desktop: 1312px;

// low height resoulution
$low-height-resolution: 675px;

// HEADER
$header-height: 128px;
$header-height-mobile: 64px;
$header-simple-height: 80px;
$header-simple-height-mobile: 64px;

$footer-simple-min-height: 373px;
$footer-margin-top: 90px;

@mixin small-mobile {
	@media (max-width: $view-size-small-mobile) { @content; }
}

@mixin medium-mobile {
	@media (max-width: $view-size-medium-mobile) { @content; }
}

@mixin mobile {
	@media (min-width: $view-size-mobile) { @content; }
}

@mixin tablet {
	@media (min-width: $view-size-tablet) { @content; }
}

@mixin small-desktop {
	@media (min-width: $view-size-small-desktop) { @content; }
}

@mixin desktop {
	@media (min-width: $view-size-desktop) { @content; }
}

@mixin large-desktop {
	@media (min-width: $view-size-large-desktop) { @content; }
}

@mixin low-height-resolution {
	@media (max-height: $low-height-resolution) { @content; }
}

@mixin focus-visible {
	transition: box-shadow 0.3s ease-in-out;
	box-shadow: 0 0 0 2px $navy-dark, 0 0 0 4px $white;
	z-index: 999;
	outline: none !important;
	outline-offset: unset !important;
}

@mixin visually-hidden {
	position: absolute;
	left: -100vw;
	width: 1px;
	height: 1px;
	overflow: hidden;
}
