@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	width: 100%;
	background-color: $black5;
	padding: 24px;
	margin-bottom: 15px;
	align-items: center;
	font-size: 12px;
	line-height: 15px;

	@include small-desktop {
		display: none;
	}

	.info {
		margin-left: 24px;
		flex: 1;
	}
}
