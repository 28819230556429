@import "~/styles/mixins.scss";
.wrapper {
	display: none;

	@include desktop {
		display: table-header-group;
	}

	.row {
		display: block;
		padding-bottom: 16px;
		margin-top: 16px;
		border-bottom: 1px solid $black10;

		@include desktop {
			display: table-row;
			padding: 0;
			margin: 0;
			border-bottom: 0;
		}
	}

	.cell {
		display: block;
		line-height: 22px;
		font-weight: 700;

		@include desktop {
			display: table-cell;
			padding: 0 16px 16px;
			border-bottom: 1px solid $black10;
			text-align: center;
			vertical-align: middle;

			&:first-child {
				padding-left: 0;
				text-align: left;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		&.isMozaic {
			font-size: 14px;
			color: $color-grey-800;
		}
	}
}
