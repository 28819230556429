@import "~/styles/mixins.scss";
.wrapper {
	padding: 20px 0 0 32px;
	margin: 0 !important;

	@include desktop {
		padding: 20px 0 0 16px;
	}

	.labelWrapper {
		margin-left: 8px;

		.label {
			color: $navy;

			&.disabled {
				color: $black55;
			}
		}

		.count {
			color: $green;
		}

		.chooseStore {
			text-decoration: underline;
			color: $navy;
			height: auto;
			padding: 0;

			&:hover {
				color: $green;
			}
		}
	}
}
