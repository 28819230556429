@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	padding: 0 32px;
	font-size: 16px;
	font-weight: 700;
	color: $navy;

	@include desktop {
		padding: 0 0 0 16px;
		color: $black65;
		margin-top: 24px;
	}

	&.open {
		.headerWrapper {
			.icon {
				transform: rotate(270deg);
			}
		}
	}

	.headerWrapper {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-right: 8px;
		cursor: pointer;
		border-bottom: 1px solid $black10;

		@include desktop {
			border-bottom: none;
			margin-right: 10px;
		}

		.header {
			font-size: 16px;
			font-weight: 600;
			margin: 16px 0;
		}

		.icon {
			transition: transform .2s ease;
			width: 8px;
			transform: rotate(90deg);
		}
	}
}
