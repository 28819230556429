@import "~/styles/mixins.scss";
.wrapper {
	flex-shrink: 0;
	flex-direction: column;
	display: none;
	padding: 48px 0 24px;
	max-width: 100%;

	@include desktop {
		display: flex;
		margin: 32px 33px 0 0;
		padding: 0;
		max-height: none;
		max-width: none;
		width: 192px;
	}
}

.modal {
	flex-shrink: 0;
	flex-direction: column;
	display: flex;
	padding: 48px 0 24px;
	max-width: 100%;
	width: 100%;
	height: 100%;
	max-height: 100%;

	@include desktop {
		display: none;
	}

	.content {
		overflow-y: auto;
	}
}
