@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;

	.label {
		display: none;
		font-weight: 600;
		font-size: 14px;
		color: $black65;

		@include tablet {
			display: flex;
		}
	}

	.controlButton {
		width: 195px;
		text-align: left;
	}

	.select {
		width: 200px;

		@include tablet {
			margin-left: 16px;
		}

		:global {
			.react-select {
				&__control {
					min-width: unset;
					width: 200px;
					display: flex;
					max-height: 40px;

					&--is-focused {
						z-index: auto;
					}

					&:focus-visible {
						@include focus-visible;
					}
				}

				&__indicator {
					pointer-events: none;
				}

				&__single-value {
					color: $black65;
				}

				&__option {
					&:active {
						background-color: $green-dark;
						color: $white;
					}

					&--is-selected {
						background-color: $green;
						color: $white;
					}
				}
			}
		}
	}
}
