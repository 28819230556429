@import "~/styles/mixins.scss";
.input {
	width: 50px;
	height: 40px;
	text-align: center;
	border: 1px solid $black10;
	font-size: 14px;
	color: $black65;

	&:focus {
		outline: 0;
		border-color: $green;
	}
}
