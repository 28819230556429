@import "~/styles/mixins.scss";
.item {
	width: 100%;
	height: 100%;

	.link {
		width: 100%;
		height: 126px;
		position: relative;
		display: inline-block;

		@include tablet {
			height: 240px;
		}

		.label {
			position: absolute;
			top: 100%;
			transform: translateY(-50%);
		}

		.image {
			object-fit: cover;
			object-position: 0;
		}
	}

	.seriesName {
		margin-top: 16px;
		font-size: 14px;
		color: $black55;
	}

	.name {
		margin-top: 16px;
		font-size: 18px;
		font-weight: 600;
		color: $navy;
		display: inline-block;

		&:hover {
			text-decoration: underline;
		}
	}

	.counter {
		margin-top: 3px;
		font-size: 14px;
		color: $black55;
	}

	.moreButton {
		margin-top: 16px;
		display: flex;
		align-items: center;
	}
}
