@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.pagination {
		display: none;

		@include tablet {
			display: flex;
		}
	}
}
