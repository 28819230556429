@import "~/styles/mixins.scss";
.modal {
	overflow-y: auto;
	padding-bottom: 120px;
	max-width: 100%;

	@include large-desktop {
		max-width: 75%;
	}

	.contentWrapper {
		flex-flow: row wrap;
		max-width: 75%;

		.contentInformation,
		.contentInput,
		.contentTitle,
		.contentButton {
			margin: 0;
		}

		.contentTitle {
			flex-basis: 100%;
			margin-bottom: 8px;
		}

		.contentInput {
			flex-basis: 50%;
			max-width: unset;
		}

		.contentButton {
			margin-left: 32px;
			align-self: flex-end;
		}

		.contentInformation {
			flex-basis: 100%;
			margin-top: 16px;
			max-width: 50%;
			margin-bottom: 32px;
		}
	}
}
