@import "~/styles/mixins.scss";
.wrapper {
	width: calc(50% - 16px);
	margin: 0 16px 40px 0;

	@include tablet {
		margin: 0 0 40px;
		width: 25%;
	}
}
