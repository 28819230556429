@import "~/styles/mixins.scss";
.wrapper {
	width: 100%;
	height: auto;
	position: relative;
	display: inline-block;
	transition: border .2s ease;
	margin: 0 16px 40px 0;

	@include tablet {
		width: 50%;
		margin: 0 0 40px;
		padding: 20px;
		border: 2px solid $white;

		&.withLink {
			&:hover {
				border: 2px solid $navy;
			}
		}
	}

	.link {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.image {
			width: 100%;
		}
	}
}
