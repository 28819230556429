@import "~/styles/mixins.scss";
.wrapper {
	.description {
		margin-top: 16px;
		font-size: 14px;
		line-height: 20px;
	}

	.notLogged {
		width: 100%;
		margin-top: 32px;
		padding: 0;
	}
}
