@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	.price {
		padding: 16px 43px 16px 32px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include desktop {
			padding: 16px;
		}

		.input {
			width: 69px;
			height: 40px;
			padding: 11px;
			text-align: center;
			border: 1px solid $black10;
			background-color: $white;
			color: $black65;
			font-size: 14px;
			font-weight: 400;
			outline: none;
		}

		.spacer {
			width: 10px;
			height: 1px;
			background: $black65;
		}
	}

	.slider {
		padding: 0 50px 0 40px;

		@include desktop {
			padding: 0 22px;
		}
	}

	.collapseContent {
		transition: height .5s;
	}
}
