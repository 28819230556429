@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 64px;

	@include tablet {
		flex-direction: row;
	}
}
