@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	line-height: 22px;
	white-space: pre;
	color: $black65;

	@include tablet {
		margin-top: 33px;
		line-height: 29px;
		white-space: normal;
	}

	.title {
		margin-bottom: 8px;
		font-size: 16px;
		line-height: 29px;

		@include tablet {
			margin-bottom: 16px;
			font-size: 24px;
		}

		.name {
			font-weight: 700;
		}
	}
}
