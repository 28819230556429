@import "~/styles/mixins.scss";
.wrapper {
	position: relative;

	&.notVisible {
		visibility: hidden;
		height: 0;
	}

	&.fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		margin: 0;
		padding: 0;
		z-index: 1000;
		background-color: $white;

		.iframe {
			height: 100vh;
		}
	}

	.iframe {
		width: 100%;
		height: 85vh;
		margin: 0;
		padding: 0;
		border: 0;
		display: none;

		@include small-desktop {
			min-height: 600px;
			display: block;
		}
	}
}
