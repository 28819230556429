@import "~/styles/mixins.scss";
.pagination {
	font-size: 14px;

	&.isMozaic {
		display: flex;
		align-items: center;

		.totalPagesText {
			color: $color-grey-900;
		}
	}

	.totalPagesText {
		margin: 0 12px 0 0;
		color: $black65;
	}
}
