@import "~/styles/mixins.scss";
.wrapper {
	.description {
		margin-top: 16px;
		font-size: 14px;
		line-height: 20px;
	}

	.buttons {
		margin-top: 32px;
		display: flex;
		gap: 16px;
	}

	.info {
		margin-top: 40px;
		padding-top: 40px;
		border-top: 1px solid $black10;
		display: flex;

		.button {
			height: auto;
			margin-left: 4px;
			padding: 0;
			color: $navy;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.modal {
	width: 640px;
}
