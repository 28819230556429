@import "~/styles/mixins.scss";
.wrapper {
	background-color: $white;
	padding-bottom: 15px;
	margin-top: 32px;

	@include desktop {
		display: none;
	}

	.button {
		width: 100%;
	}
}
