@import "~/styles/mixins.scss";
.wrapper {
	padding: 15px 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 20px 20px 0;
	width: 100%;

	@include mobile {
		width: auto;
	}

	.name {
		font-size: 14px;
		line-height: 17px;
		color: $navy;
	}

	.count {
		margin-left: 5px;
		font-size: 14px;
		line-height: 17px;
		color: $green;
	}
}
