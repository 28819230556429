@import "~/styles/mixins.scss";
.modal {
	max-width: 850px;
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 48px;

	.label {
		font-size: 12px;
		color: $black65;

		a {
			color: $navy !important;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.buttons {
		display: flex;
		gap: 32px;

		.button {
			width: 280px;
			padding: 24px;
			white-space: break-spaces;
		}
	}
}
