@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	@include desktop {
		margin-bottom: 32px;
	}

	.categories {
		padding-top: 24px;

		@include desktop {
			padding: 24px 16px 0;
		}
	}

	.collapseContent {
		transition: height .5s;
	}

	.header {
		border-top: 0;
	}
}
