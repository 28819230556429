@import "~/styles/mixins.scss";
.prevPageButton,
.nextPageButton {
	display: inline-flex !important;
	align-items: center;
	color: $navy;
	text-decoration: underline;

	.label {
		&.isMozaic {
			color: $color-grey-900;
		}
	}
}

.prevPageButton {
	margin: 0 12px 0 0;

	.pageButtonIcon {
		margin: 0 16px 0 0;
		transform: rotate(180deg);
	}
}

.nextPageButton {
	margin: 0 0 0 12px;

	&.isMozaic {
		margin: 0;
	}

	.pageButtonIcon {
		margin: 0 0 0 16px;
	}
}
