@import "~/styles/mixins.scss";
.modal {
	.title {
		font-size: 18px;
		line-height: 21px;
		font-weight: 700;
	}

	.item {
		display: flex;
		flex-direction: column;
		margin: 26px 0 38px;

		@include tablet {
			flex-direction: row;
		}

		.itemDescription {
			margin-top: 16px;
			line-height: 22px;

			@include tablet {
				margin: 0 0 0 16px;
			}

			.label {
				font-weight: 700;
			}
		}
	}

	.buttons {
		display: flex;
		flex-direction: column;

		@include tablet {
			flex-direction: row;
		}

		.button {
			@include tablet {
				width: 173px;
			}

			&:first-child {
				margin-bottom: 23px;

				@include tablet {
					margin: 0 15px 0 0;
				}
			}
		}
	}
}
