@import "~/styles/mixins.scss";
.item {
	padding: 8px 0 8px 32px;
	font-size: 14px;
	line-height: 17px;
	display: block;
	color: $navy;

	&:hover {
		text-decoration: underline;
		color: $navy;
	}

	@include desktop {
		padding: 8px 0 8px 16px;
	}

	&.active {
		font-weight: 700;
	}

	.count {
		color: $green;
	}
}
