@import "~/styles/mixins.scss";
.modal {
	max-width: 850px;
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}
