@import "~/styles/mixins.scss";
.wrapper {
	.description {
		margin-top: 16px;
		font-size: 14px;
		line-height: 20px;
	}

	.submitButton {
		width: 100%;
		margin-top: 40px;
	}

	.agreement {
		margin-top: 24px;
		font-size: 12px;
		line-height: 17px;
	}
}

.modal {
	width: 560px;
}
