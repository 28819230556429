@import "~/styles/mixins.scss";
.wrapper {
	display: block;
	padding-bottom: 16px;
	margin-top: 16px;
	border-bottom: 1px solid $black10;

	&:first-child {
		border-top: 1px solid $black10;
		padding-top: 16px;
		margin-top: 0;

		@include small-desktop {
			border-top-width: 0;
			padding-top: 0;
		}
	}

	@include desktop {
		display: table-row;
		padding: 0;
		margin: 0;
		border-bottom: 0;
	}

	.mobileCell {
		display: flex;
		justify-content: space-between;
		width: 100%;
		line-height: 22px;

		@include desktop {
			display: table-cell;
			padding: 16px;
			width: unset;
			border-bottom: 1px solid $black10;
			text-align: center;
			vertical-align: middle;

			&:first-child {
				padding-left: 0;
				text-align: left;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		.thumbnailWrapper {
			margin-bottom: 15px;
			max-width: 100px;
			max-height: 75px;

			@include desktop {
				margin-bottom: 0;
			}
		}
	}

	.cell {
		display: block;
		line-height: 22px;

		@include desktop {
			display: table-cell;
			padding: 16px;
			border-bottom: 1px solid $black10;
			text-align: center;
			vertical-align: middle;

			&:first-child {
				padding-left: 0;
				text-align: left;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		&.isMozaic {
			color: $color-grey-800;
			font-size: 14px;
		}
	}

	.title {
		font-weight: 700;

		@include desktop {
			display: none;
		}
	}
}
