@import "~/styles/mixins.scss";
.prevPageButton,
.nextPageButton {
	display: inline-flex;
	align-items: center;
	color: $navy;
	text-decoration: underline;
}

.prevPageButton {
	margin: 0 12px 0 0;

	.pageButtonIcon {
		margin: 0 16px 0 0;
		transform: rotate(180deg);
	}
}

.nextPageButton {
	margin: 0 0 0 12px;

	.pageButtonIcon {
		margin: 0 0 0 16px;
	}
}
