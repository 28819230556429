@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
	border-bottom: 1px solid $black10;
	width: 100%;

	@include desktop {
		margin-bottom: 40px;
	}

	.title {
		font-size: 20px;
		font-weight: 600;
		color: $black65;
		position: relative;
		top: 1px;
		width: fit-content;
		line-height: 24px;
		padding-bottom: 7px;
		border-bottom: 1px solid $green;

		@include tablet {
			white-space: pre;
		}

		@include desktop {
			font-size: 24px;
			padding-bottom: 16px;
		}

		.counter {
			display: inline-block;
			margin-left: 5px;
			color: $green;
		}
	}

	.badge {
		align-self: flex-start;
		margin-left: 16px;
	}

	&.isMozaic {
		border-bottom: none;
	}
}
