@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	color: $navy;
	line-height: 17px;
	transition: color .3s ease;

	&:hover {
		color: $green;
	}

	&.active {
		color: $navy;
		font-weight: 700;
	}

	.count {
		color: $green;
	}

	.icon {
		transform: rotate(90deg);

		&.open {
			transform: rotate(270deg);
		}
	}
}
