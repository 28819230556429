@import "~/styles/mixins.scss";
.wrapper {
	color: $black65;

	.title {
		font-size: 20px;
		margin-top: 20px;
	}

	.tip {
		font-size: 16px;
		margin-top: 15px;
	}

	.showAll {
		color: $green;
		display: inline-block;
		font-size: 16px;
		margin-top: 10px;
	}
}
