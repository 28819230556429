@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin: 40px 16px 20px;

	@include desktop {
		margin: 40px 0 0;
	}

	.reset {
		margin-top: 25px;
		font-size: 14px;
		color: $navy;

		@include desktop {
			margin-top: 8px;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}
