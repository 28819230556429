@import "~/styles/mixins.scss";
.wrapper {
	display: inline-block;
	line-height: 22px;
	position: relative;

	.actionLink {
		color: $black10;

		@include small-desktop {
			color: $navy;

			&:hover {
				color: $green;
			}
		}

		&.isMozaic {
			color: $color-primary-01-600;
			text-decoration: underline;
		}
	}

	.icon {
		margin-right: 8px;
	}
}
