@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 40px;

	.name {
		font-weight: 700;
		font-size: 14px;
		max-width: 730px;
		color: $black65;
	}
}
