@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	margin-top: 96px;

	.categories {
		color: $navy;
		border: 1px solid $black10;

		&:hover {
			color: $white;
			background-color: $navy;
		}
	}
}
