@import "~/styles/mixins.scss";
.wrapper {
	margin: 0 32px 16px;
	border-bottom: 1px $black10 solid;
	padding: 0 8px 16px 0;

	@include desktop {
		margin: 0;
		margin-bottom: 16px;
		border-width: 0;
		padding: 0;
	}

	&:last-child {
		margin-bottom: 0;
		border-width: 0;
	}

	.collapseContent {
		transition: height .5s;
	}
}
