@import "~/styles/mixins.scss";
.carousel {
	margin-top: 24px;

	@include tablet {
		margin-top: 49px;
	}

	.slide {
		width: 218px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include tablet {
			&:first-child {
				width: 416px;
			}
		}
	}
}
