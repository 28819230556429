@import "~/styles/mixins.scss";
.wrapper {
	padding-left: 15px;
	margin-top: 24px;
	position: relative;

	@include desktop {
		margin-top: 8px;
	}

	&:before {
		content: "";
		height: 1px;
		width: 10px;
		position: absolute;
		top: 7px;
		left: 0;
		background-color: $gray90;
	}

	&:after {
		content: "";
		height: calc(100% + 24px);
		width: 1px;
		position: absolute;
		top: -21px;
		left: 0;
		background-color: $gray90;

		@include desktop {
			height: calc(100% + 8px);
			top: -5px;
		}
	}

	&:last-child {
		&:after {
			height: 29px;

			@include desktop {
				height: 13px;
			}
		}
	}

	.link {
		color: $navy;
		line-height: 17px;
		transition: color .3s ease;

		&:hover {
			color: $green;
		}

		&.active {
			color: $navy;
			font-weight: 700;
		}

		.count {
			color: $green;
		}
	}
}
