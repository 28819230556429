@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	@include desktop {
		margin-top: 24px;
	}

	&.open {
		.headerWrapper {
			.icon {
				transform: rotate(270deg);
			}
		}
	}

	.headerWrapper {
		display: flex;
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
		color: $navy;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		margin: 0 42px 0 32px;
		padding-right: 8px;
		border-bottom: 1px solid $black10;

		@include desktop {
			border-bottom: none;
			padding: 0 18px 0 16px;
			margin: 0;
		}

		.header {
			margin: 16px 0;

			@include desktop {
				margin: 0;
				width: 155px;
			}
		}

		.icon {
			transition: transform .2s ease;
			width: 10px;
			transform: rotate(90deg);
		}
	}

	.collapseContent {
		transition: height .5s;
	}
}
