@import "~/styles/mixins.scss";
.wrapper {
	.unavailability {
		margin-top: 24px;
		margin-bottom: 0;
		display: flex;

		@include small-desktop {
			display: none;
		}
	}

	.content {
		margin-top: 24px;
		display: none;

		@include small-desktop {
			margin-top: 32px;
			display: block;
		}
	}
}
