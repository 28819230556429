@import "~/styles/mixins.scss";
.perPage {
	display: inline-block;

	.label {
		display: none;
		margin-right: 16px;
		font-weight: 700;
		font-size: 14px;
		color: $black65;

		@include tablet {
			display: inline-flex;
			align-items: center;
		}

		&.isMozaic {
			font-size: 16px;
			color: $color-grey-900;
		}
	}

	.select {
		display: inline-block;

		:global {
			.react-select {
				&__control {
					min-width: unset;
					max-height: 40px;
					display: flex;

					&--is-focused {
						z-index: auto;
					}

					&:focus-visible {
						@include focus-visible;
					}
				}

				&__value-container {
					padding: 0 6px 0 16px;
				}

				&__single-value {
					color: $black65;
				}

				&__indicator {
					pointer-events: none;

					&.react-select__loading-indicator {
						position: absolute;
						left: 0;
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						background: $white;
						opacity: 0.8;
						height: 100%;
						padding: 0;
					}
				}

				&__option {
					&:active {
						background-color: $green-dark;
						color: $white;
					}

					&--is-selected {
						background-color: $green;
						color: $white;
					}
				}
			}
		}

		&.isMozaic {
			:global {
				.react-select {
					&__control {
						border-radius: 4px;
						border-color: $color-grey-900;
					}

					&__option {
						&:active {
							background-color: $color-secondary-green-500;
							color: $white;
						}

						&--is-selected {
							background-color: $color-secondary-green-500;
							color: $white;
						}
					}

					&__indicator {
						color: $black;
					}

					&__single-value {
						font-size: 16px;
						color: $color-grey-900;
					}
				}
			}
		}
	}
}
