@import "~/styles/mixins.scss";
.paginationSmall {
	display: inline-flex;
	align-items: center;
	color: $black65;

	.totalPagesText {
		display: inline-block;
		margin: 0 16px;
	}
}
