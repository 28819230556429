@import "~/styles/mixins.scss";
.wrapper {
	display: flex;
	flex-direction: column;

	.categories {
		padding: 16px 0;
	}

	.header {
		border-top: 0;
	}
}
