@import "~/styles/mixins.scss";
.wrapper {
	margin-top: 16px;

	@include tablet {
		margin-top: 32px;
	}

	.title {
		color: $black65;
		font-size: 16px;
		line-height: 29px;

		@include tablet {
			font-size: 24px;
		}

		.name {
			font-weight: 700;
		}
	}

	.infoWrapper {
		display: flex;
		color: $black65;
		margin: 16px -16px 0;
		flex-direction: column;
		padding: 24px 16px;
		background-color: $black5;
		line-height: 20px;

		@include tablet {
			margin: 32px 0 0;
			padding: 40px;
		}

		.listTitle {
			margin: 16px 0 8px;

			@include tablet {
				margin: 24px 0 16px;
			}
		}

		.list {
			list-style: none;

			.listElement {
				margin-bottom: 8px;
				display: flex;

				&:last-child {
					margin: 0;
				}

				&:before {
					content: "•";
					color: $green;
					font-weight: 700;
					font-size: 24px;
					margin: 1px 10px 0 0;
				}
			}
		}

		.buttonsWrapper {
			display: flex;
			margin-top: 24px;

			@include tablet {
				margin-top: 40px;
			}

			.button {
				flex: 1;

				@include tablet {
					max-width: 144px;
				}

				&:first-child {
					margin-right: 16px;
				}
			}
		}
	}
}
