@import "~/styles/mixins.scss";
.wrapper {
	@include tablet {
		display: flex;
		align-items: center;
		margin-top: 8px;
	}

	.caption {
		line-height: 20px;

		@include tablet {
			flex-shrink: 0;
			max-width: 25%;
			margin-right: 20px;
		}
	}
}
